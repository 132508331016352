import React from "react";
import moment from "moment";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { useSearchParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";

import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";

import ErrorIcon from "@mui/icons-material/Error";

import trackTrace from "../../assets/trackTrace.png";

import ApiService from "../../services/apiService";
import { rootShouldForwardProp } from "@mui/material/styles/styled";

function TrackTrace() {
  let [searchParams, setSearchParams] = useSearchParams();
  const [isTracking, setIsTracking] = React.useState(false);
  const [orNumber, setOrNumber] = React.useState("");

  const [trackDetails, setTrackDetails] = React.useState([]);

  React.useEffect(() => {
    let getOr = searchParams.get("or");
 
  if (getOr) {
    setOrNumber(getOr);
 
    setIsTracking(true);
    setTrackDetails([]);

      ApiService.trackParcel(getOr)
        .then((response) => response.json())
        .then((json) => {
          if (json.length > 0) {
            let trackingResult = json.sort(custom_sort);
            let result = [];

            trackingResult.forEach((x) => {
              let track = {
                status:
                  x.statusID == 1 ? "Accepted at Origin Branch" : x.statusText,
                dateTime: moment(x.dateReceived.replace("Z", ""))
                  .add(8, "hours")
                  .format("M/DD/YYYY h:mm a"),
                receiver:
                  x.receiver === null || x.receiver === ""
                    ? x.location === null
                      ? ""
                      : x.location
                    : x.receiver,
                remarks: x.remarks,
                messenger: x.messenger,
                relation: x.relation,
              };

              //track.dateTime.setHours(track.dateTime.getHours() + 8);

              result.push(track);
            });

            //10272023

            // const uniqueTracking = new Map();
            // const uniqueArray = result.filter((obj) => {
            //   if (
            //     !uniqueTracking.has(obj.receiver) &&
            //     !uniqueTracking.has(obj.messenger) &&
            //     !uniqueTracking.has(obj.dateTime)
            //   ) {
            //     uniqueTracking.set(obj.dateTime, true);

            //     return true;
            //   }
            //   return false;
            // });
            // //    console.log(uniqueArray);
            // console.log("trackingResult", uniqueArray);
            setTrackDetails(result);
          }

          setIsTracking(false);
        });
    }
  }, []);

  const trackParcel = () => {
    setTrackDetails([]);
    setIsTracking(true);

    ApiService.trackParcel(orNumber)
      .then((response) => response.json())
      .then((json) => {
        if (json.length > 0) {
          let trackingResult = json.sort(custom_sort);
          let result = [];

          trackingResult.forEach((x) => {
            let track = {
              status:
                x.statusID == 1 ? "Accepted at Origin Branch" : x.statusText,
              dateTime: moment(x.dateReceived.replace("Z", ""))
                .add(8, "hours")
                .format("M/DD/YYYY h:mm a"),
              receiver:
                x.receiver === null || x.receiver === ""
                  ? x.location === null
                    ? ""
                    : x.location
                  : x.receiver,
              remarks: x.remarks,
              messenger: x.messenger,
              relation: x.relation,
            };

            //track.dateTime.setHours(track.dateTime.getHours() + 8);

            result.push(track);
          });
        //   const uniqueTracking = new Map();
        //   const uniqueArray = result.filter((obj) => {
        //     if (
        //       !uniqueTracking.has(obj.receiver) &&
        //       !uniqueTracking.has(obj.messenger) &&
        //       !uniqueTracking.has(obj.dateTime)
        //     ) {
        //       uniqueTracking.set(obj.dateTime, true);

        //       return true;
        //     }
        //     return false;
        //   });
        //   //    console.log(uniqueArray);
        //   console.log("trackingResult", uniqueArray);
          setTrackDetails(result);
        }

        setIsTracking(false);
      });
  };

  const custom_sort = (a, b) => {
    return (
      new Date(b.dateReceived.replace("Z", "")).getTime() -
      new Date(a.dateReceived.replace("Z", "")).getTime()
    );
  };

  return (
    <>
      <Header />

      <Box
        sx={{
          display: { xs: "flex", sm: "flex", md: "none" },
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "30px",
        }}
      >
        <Typography variant="h6" gutterBottom>
          <b>Track & Trace</b>
        </Typography>

        <Divider
          sx={{
            width: "50px",
            border: "1px solid #046A38",
            marginBottom: "0px",
          }}
        />
      </Box>

      <Box
        sx={{
          display: { xs: "none", sm: "none", md: "flex" },
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "30px",
        }}
      >
        <Typography variant="h6" gutterBottom>
          <b>Track & Trace</b>
        </Typography>
      </Box>

      {isTracking ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "30px",
            marginBottom: "30px",
          }}
        >
          <CircularProgress sx={{ color: "#046A38" }} />
          <Typography
            variant="subtitle1"
            sx={{ color: "#046A38", fontWeight: "bold" }}
          >
            Tracking...
          </Typography>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "flex" },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "70px",
              }}
            >
              <Box
                sx={{
                  marginRight: "40px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                  <TextField
                    id="outlined-basic"
                    label="Tracking Number"
                    variant="outlined"
                    placeholder="ex.123456-123"
                    value={orNumber}
                    sx={{ width: "420px" }}
                    onChange={(e) => {
                      setOrNumber(e.target.value);
                    }}
                  />
              </Box>
            
              <Box>
                <Button
                  sx={{
                    backgroundColor: "#046A38",
                    color: "#fff",
                    height: "60px",
                    width: "150px",
                    fontWeight: "bold",
                    "&:hover": { backgroundColor: "#046A38", color: "#fff" },
                  }}
                  startIcon={<SearchIcon />}
                  onClick={trackParcel}
                >
                  Track
                </Button>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: { xs: "flex", sm: "flex", md: "none" },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                paddingTop: "5px",
                paddingLeft: "15px",
                paddingRight: "15px",
                display: "flex",
                flexDirection: "column",
              }}
            >
                <TextField
                  id="outlined-basic"
                  label="Tracking Number"
                  variant="outlined"
                  placeholder="ex.123456-123"
                  sx={{ marginTop: "15px", marginBottom: "15px" }}
                  value={orNumber}
                  onChange={(e) => {
                    setOrNumber(e.target.value);
                  }}
                />
           

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "15px",
                  marginBottom: "30px",
                }}
              >
                <Button
                  sx={{
                    backgroundColor: "#046A38",
                    color: "#fff",
                    height: "60px",
                    width: "173px",
                    fontWeight: "bold",
                    "&:hover": { backgroundColor: "#046A38", color: "#fff" },
                  }}
                  startIcon={<SearchIcon />}
                  onClick={trackParcel}
                >
                  Track
                </Button>
              </Box>
            </Box>
          </Box>
          {trackDetails.length === 0 ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "60px",
                  marginTop: "60px",
                }}
              >
                <Box>
                  <ErrorIcon sx={{ color: "#046A38" }} />
                </Box>
                <Box sx={{ marginLeft: "15px" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "#046A38", fontWeight: "bold" }}
                  >
                    No Tracking Info Yet
                  </Typography>
                </Box>
              </Box>
              <Divider />
            </>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {/* <Box sx={{width: {xs: '80%', sm:'80%', md:'50%'}, marginBottom:'30px'}}>
                                    <img src={trackTrace} style={{width:'100%'}}/>
                                </Box> */}

                {/* for mobile */}
                <Box
                  sx={{
                    display: { xs: "flex", sm: "flex", md: "none" },
                    flexDirection: "column",
                    width: "75%",
                    margin: "0 auto",
                    marginTop: "30px",
                  }}
                >
                  {trackDetails.map((row, index) => (
                    <Box sx={{ display: "flex", paddingLeft: "15px" }}>
                      <Box sx={{ minHeight: "100px" }}>
                        <Box
                          sx={{
                            width: "15px",
                            height: "15px",
                            borderRadius: "50%",
                            backgroundColor: "#046A38",
                            flexGrow: 1,
                          }}
                        ></Box>
                        {index + 1 < trackDetails.length ? (
                          <Box
                            sx={{
                              width: "3px",
                              height: "100px",
                              backgroundColor: "#046A38",
                              position: "relative",
                              left: "6px",
                            }}
                          ></Box>
                        ) : (
                          ""
                        )}
                      </Box>
                      <Box
                        sx={{
                          paddingLeft: "30px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "start",
                        }}
                      >
                        <Typography variant="h6">{row.status}</Typography>
                        <Typography variant="subtitle1">
                          {row.dateTime}
                        </Typography>
                        <Typography variant="subtitle1">
                        {row.relation ? `${row.receiver} - Relationship: ${row.relation}` : row.receiver}
                        </Typography>
                        <Typography variant="subtitle1">
                          {row.remarks}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>

                {/* for non-mobile */}
                <Box
                  sx={{
                    display: { xs: "none", sm: "none", md: "flex" },
                    flexDirection: "column",
                    width: "75%",
                    margin: "0 auto",
                    marginTop: "30px",
                  }}
                >
                  <Box sx={{ display: "flex", paddingLeft: "15px" }}>
                    <Box sx={{ flex: 1 }}></Box>
                    <Box
                      sx={{
                        flex: 6,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                      }}
                    >
                      <Typography variant="h6">Date</Typography>
                    </Box>
                    <Box
                      sx={{
                        flex: 6,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                      }}
                    >
                      <Typography variant="h6">Status</Typography>
                    </Box>
                    <Box
                      sx={{
                        flex: 6,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                      }}
                    >
                      <Typography variant="h6">Receiver</Typography>
                    </Box>
                    <Box
                      sx={{
                        flex: 6,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                      }}
                    >
                      <Typography variant="h6">Remarks</Typography>
                    </Box>
                  </Box>
                  <Box sx={{ marginTop: "30px", marginBottom: "30px" }}>
                    <Divider />
                  </Box>

                  {trackDetails.map((row, index) => (
                    <Box sx={{ display: "flex", paddingLeft: "15px" }}>
                      <Box sx={{ flex: 1, minHeight: "60px" }}>
                        <Box
                          sx={{
                            width: "15px",
                            height: "15px",
                            borderRadius: "50%",
                            backgroundColor: "#046A38",
                            marginLeft: "5px",
                          }}
                        ></Box>
                        {index + 1 < trackDetails.length ? (
                          <Box
                            sx={{
                              width: "3px",
                              height: "60px",
                              backgroundColor: "#046A38",
                              position: "relative",
                              left: "6px",
                              marginLeft: "5px",
                            }}
                          ></Box>
                        ) : (
                          ""
                        )}
                      </Box>
                      <Box
                        sx={{
                          flex: 6,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "start",
                          position: "relative",
                          top: "-5px",
                        }}
                      >
                        <Typography variant="subtitle1">
                          {row.dateTime}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          flex: 6,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "start",
                          position: "relative",
                          top: "-5px",
                        }}
                      >
                        <Typography variant="subtitle1">
                          {row.status}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          flex: 6,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "start",
                          position: "relative",
                          top: "-5px",
                        }}
                      >
                        <Typography variant="subtitle1">
                        {row.relation ? `${row.receiver} - Relationship: ${row.relation}` : row.receiver}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          flex: 6,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "start",
                          position: "relative",
                          top: "-5px",
                        }}
                      >
                        <Typography variant="subtitle1">
                          {row.remarks}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
              <Divider />
            </>
          )}
        </>
      )}

      <Footer />
    </>
  );
}

export default TrackTrace;
